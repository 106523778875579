.registration-container {
  width: 930px;  /* Desktop width */
  height: 380px;  
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-items: center;
  
  /* padding: 20px; */
  box-sizing: border-box;
}

.welcome-screen {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 930px;  /* Desktop width */
  height: 380px;  
  background-image: url('./../assets/trivia-banner-holder-removebg-preview.png');

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
 
  
}

.welcome-screen h1 {
  color: yellow;
  font-size: clamp(2.5rem, 7vw, 4rem);
  line-height: 2.2;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.press-start {
  color: #c15ec7;
  font-size: clamp(1rem, 3vw, 24px);
  margin: 0;
  padding: 10px 20px;
  /* background-color: rgba(0, 0, 0, 0.3); */
  border-radius: 6px;
  transition: transform 0.2s ease;
}

.press-start:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.welcome-screen .text-xl {
  color: #fff;
  font-size: clamp(1rem, 2vw, 1.25rem);
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px 20px;
  /* border-radius: 6px; */
}

/* .pixel-border-top,
.pixel-border-bottom {
  width: 100%;
  height: 20px;
  background: repeating-linear-gradient(
    to right,
    #c15ec7 0px,
    #c15ec7 20px,
    transparent 20px,
    transparent 40px
  );
} */

@media screen and (max-width: 768px) {
  .registration-container {
    height: 110%;
    width: 100%;
    padding-top: 0;
  }
  
  .welcome-screen {
    gap:0px;  }

  .welcome-screen h1 {
    font-size: 1.5rem;
  }
}
