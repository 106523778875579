.trivia-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background: url('../assets/trivia-container-bg.jpeg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: transparent;
  font-family: "Eight Bit", sans-serif;
} 

.trivia-inner-container {
  width: 100%;
  max-width: 1200px;
  min-height: 96vh;
  background: url('../assets/trivia-bg.svg') no-repeat center center;
  background-size: contain;
  background-position: center bottom;
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  z-index: 1;
}

@media (max-width: 768px) {
  .trivia-container {
    min-height: 100vh;
    /* padding: 10px; */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .trivia-inner-container {
    width: 100%;
    background-color: red;
    padding: 20px;
    background: url('../assets/trivia-bg-mobile.jpeg') no-repeat center center;
    min-height: 93vh;
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
    align-self: flex-end;
  }
}
