.question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.question-header {
  width: 100%;
  text-align: center;
}

.question-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--defaultYellow);
}

.answer-indicators {
  display: flex;
  gap: 5px;
}

.indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.indicator.correct {
  background-color: #00ff00;
}

.indicator.incorrect {
  background-color: #ff0000;
}

.question-text {
  max-height: 100px;
  text-align: center;
  font-size: 24px;
  color: gray;
  /* padding: 0 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.options-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 600px;
  padding: 0 20px;
  
}

.timer {
  font-size: 1.75rem;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: start;
  color: var(--defaultYellow);
}

.timer-icon {
  max-width: 30px;
  max-height: 30px;
}

@media screen and (max-width: 585px) {
  .question-container {
    padding: 0;
  }

  .question-text {
    font-size: 18px;
    padding: 0 5px;
    /* height: 60px; */
    min-height: 20px;
    max-height: 20px;
    /* margin: 5px 0; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .options-grid {
    gap: 0.25rem;
  }

  .timer {
    font-size: 1.25rem;
    justify-content: start;
  }

  .timer-icon {
    width: 18px;
    height: 18px;
  }
}
