.bonusCardMobile {
  background-image: url(../../../assets/images/bonusCardMobile.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.status-grid {
  @apply gap-x-0 gap-y-8;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1.5rem;
}


.gametype-btn {
  background-image: url(../../../assets/images/gametype-button.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 200px;
  height: 88px;
  transform: translateY(-52px);
  font-family: "Eight Bit", sans-serif;
  cursor: pointer;
}

.status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0;
}

.status-item img {
  width: 32px;
  height: 32px;
}

.multiplier {
  color: var(--defaultBlack);
  font-weight: bold;
  margin-top: -16px;
}

@media (max-width: 768px) {
  .gametype-btn {
    background-image: url(../../../assets/images/cardBtnMobPurple.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 128px;
    height: 80px;
    transform: translateY(-16px);
    cursor: pointer;
  }

  .activeMode {
    background-image: url(../../../assets/images/cardBtnMobYellow.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 144px;
    height: 88px;
    transform: translateY(-24px);
  }

  .status-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* @keyframes pulse {
  0%, 100% { transform: scale(1) translateY(-52px); }
  50% { transform: scale(1.1) translateY(-52px); }
} */

.gametype-btn {
  animation: pulse 0.15s ease-in;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  animation-play-state: running;
  animation-fill-mode: both;
  animation-direction: normal;
  animation-timing-function: ease-out;
  animation-duration: 1.5s;
}

/* For mobile version */
@media (max-width: 768px) {
  @keyframes pulseMobile {

    0%,
    100% {
      transform: scale(1) translateY(-16px);
    }

    50% {
      transform: scale(1.1) translateY(-16px);
    }
  }

  .gametype-btn {
    animation: pulseMobile 0.15s ease-in;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    animation-play-state: running;
    animation-fill-mode: both;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-duration: 1.5s;
  }

  /* Disable animation for active mode */
  .activeMode {
    animation: none;
  }
}