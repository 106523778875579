.preview-section {
  display: flex;
  justify-content: center;
  background-color: var(--darkPurple);
  width: 100vw;
}

.preview-container {
  padding: 4rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: var(--contentWidth);
  width: 100%;
  max-width: var(--contentWidth);
}

.preview-title {
  font-size: var(--section-heading);
  color: #ffd700;
  margin: 0;
  font-family: 'Eight Bit', sans-serif;
  margin-bottom: 2rem;
  width: 100%;
}

.preview-carousel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
}

.carousel-button {
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 1rem;
  z-index: 2;
}

.carousel-button:disabled {
  color: #666;
  cursor: not-allowed;
}

.carousel-button:not(:disabled):hover {
  color: #ffd700;
}

.carousel-viewport {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel-content {
  display: flex;
  gap: 1rem;
  transition: transform 0.3s ease;
}

.preview-frame {
  flex: 0 0 300px;
  border: 4px solid #ffa500;
  border-radius: 10px;
  padding: 10px;
  background-color: #2a1f3e;
}

.preview-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.newsletter-section {
  width: 100%;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;

}

.newsletter-title {
  font-size: var(--section-heading);
  color: var(--defaultYellow);
  margin: 0;
  font-family: 'Eight Bit', sans-serif;
  line-height: 80px;
}

.newsletter-description {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 2.5rem;
  opacity: 0.8;
  margin-top: 1.5rem;
}

.newsletter-form {
  display: flex;
  gap: 1rem;
  max-width: 640px;
  margin: 0;
  width: 100%;
}

.newsletter-input {
  flex: 1;
  border-radius: 4px;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3.325rem;
  color: #fff;
  font-size: 1rem;
  outline: none;
  background-image: url(../../../../assets/images/input.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
}

.newsletter-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.subscribe-button {
  border: none;
  color: #1a0f2e;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-image: url(../../../../assets/images/formbtn.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  height: 3.375rem;
  width: 168px;
  font-family: 'Eight Bit', sans-serif;
  font-size: 1.625rem;
}

.subscribe-button:hover {
  background-color: #ffed4a;
}

@media (max-width: 768px) {
  .newsletter-title {
    font-size: 64px;
    line-height: 64px;
  }

  .newsletter-form {
    display: flex;
    flex-direction: column;
    height: 112px;
  }

  .newsletter-section {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .newsletter-input {
    max-height: 44px;
  }

  .subscribe-button {
    height: 48px;
    width: 140px;
  }
}