.scrolling-banner {
  background: #FFD700;
  overflow: hidden;
  width: 100%;
  padding: 10px 0;
}

.mission-container {
  /* min-height: 100vh; */
  /* background: url('./BG-05.jpg') no-repeat center center; */
  /* background-size: cover; */
  /* padding: 6rem 2rem 2rem; */
  position: relative;
  background-color: var(--darkPurple);
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
}

.mission-header {
  color: var(--defaultYellow);
  font-size: var(--section-heading);
  width: 100%;
  font-family: 'Eight Bit', sans-serif;
}

.mission-paragraph {
  font-size: 1rem;
  min-width: 100%;
}

.mission-content {
  max-width: 1196px;
  margin: 0 auto;
  align-items: flex-start;
  gap: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}

.scrolling-content {
  display: flex;
  justify-content: space-around;
  width: 200%;
  animation: scroll 15s linear infinite;
  align-items: center;
}

.scrolling-content span {
  flex: 1;
  text-align: center;
  font-size: 2.5rem;
  white-space: nowrap;
  color: var(--defaultBlack);
  font-family: 'Eight Bit', sans-serif;
  text-transform: uppercase;

}

.diamond {
  color: #000;
  flex: 0.5;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.video-preview {
  width: 100%;
  max-width: 1120px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.video-preview img {
  width: 100%;
  height: auto;
}

.mission-grid {
  /* background-color: #f0f0f0; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 1196px;
  margin: 0 auto;
  padding: 2rem;
  padding-left: 0;
}

.mission-item {
  display: flex;
  align-items: center;
  gap: 1rem;

}

.mission-item .icon {
  flex-shrink: 0;
}

.mission-item p {
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.5;
}

.hero-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  max-width: 800px;
}

h1 {
  font-size: clamp(2.5rem, 5vw, 4rem);
  color: #FFE600;
  line-height: 1.2;
  text-align: left;
  margin-bottom: 1rem;
}

p {
  color: white;
  font-size: 1.1rem;
  max-width: 500px;
  margin: 0;
  text-align: left;
}

@media (max-width: 768px) {
  .video-preview {
    width: 100%;
    max-width: 98vw;
    margin: 2rem auto;
    padding: 0 1rem;
  }
  
  .video-preview img {
    width: 100%;
    height: auto;
  }

  .mission-header {
    font-size: 64px;
    width: 100%;
    font-family: 'Eight Bit', sans-serif;
  }

  .mission-content {
    margin: 0 auto;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .mission-grid {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0 auto;
    padding-left: 1rem;
  }

  .mission-item {
    padding-bottom: 1rem;
    border-bottom: 1px solid lightgray;
  }

  .scrolling-content span {
    font-size: 1.75rem;
  
  }

  .scrolling-content {
  gap: 8px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .mission-content {
    padding-left: 1rem;
  }
}