.quiz-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
 
  
  .header {
    position: relative;
    height: 8px;
    background: #eee;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .timer-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #2D46B9;
    transition: width 1s linear;
  }
  
  .timer-count {
    position: absolute;
    right: 0;
    top: 12px;
    font-size: 18px;
    font-weight: 600;
  }
  
  .question-section {
    text-align: center;
    padding: 20px;
  }
  
  .question-section h2 {
    font-size: 24px;
    color: #1a1a1a;
    margin: 0;
  }

  .question-container {
    position: relative;
  }

  .question-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
  }
  
  .options-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    padding: 20px;
  }
  
  .option-button {
    border: none;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
    cursor: pointer;
    padding: 0;
    height: 64px;
  }
  
  .option-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .option-button.selected {
    background: #2D46B9;
    color: white;
  }
  
  .option-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0;
  }
  
  .option-marker {
    font-size: 24px;
    font-weight: 600;
    height: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    align-items: center;
    background-color: #2D46B9;
    color: white;
  }
  
  .option-text {
    font-size: 18px;
    font-weight: 500;
    margin-left: 32px;
    color: black;
  }
  
  .completion-screen {
    text-align: center;
    padding: 40px;
  }
  
  .score-display {
    font-size: 48px;
    font-weight: 700;
    margin: 24px 0;
  }
  
  .reset-button {
    background: #2D46B9;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: background 0.2s ease;
  }
  
  .reset-button:hover {
    background: #1a2f8f;
  }
  
  .loading-state,
  .error-state,
  .no-quiz-state {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 18px;
    color: #666;
  }

  /* Update existing styles */
  .game-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
  }
  
  .game-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .game-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .question-counter {
    font-size: 18px;
    font-weight: 600;
    color: #1a1a1a;
  }
  
  .progress-dots {
    display: flex;
    gap: 6px;
  }
  
  .progress-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #e0e0e0;
    transition: background-color 0.3s ease;
  }
  
  .progress-dot.completed {
    background: #2D46B9;
  }
  
  .timer-wrapper {
    position: relative;
    width: 52px;
    height: 52px;
  }
  
  .timer-circle {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .timer-number {
    position: absolute;
    font-size: 20px;
    font-weight: 600;
    color: #1a1a1a;
    z-index: 1;
  }
  
  .timer-svg {
    position: absolute;
    width: 52px;
    height: 52px;
    transform: rotate(-90deg);
  }
  
  .timer-circle-bg,
  .timer-circle-progress {
    fill: none;
    stroke-width: 3;
  }
  
  .timer-circle-bg {
    stroke: #f0f0f0;
  }
  
  .timer-circle-progress {
    stroke: #2D46B9;
    stroke-linecap: round;
    stroke-dasharray: 151px;
    transition: stroke-dashoffset 1s linear;
  }
  
  /* Update question section */
  .question-section {
    text-align: center;
    padding: 32px 20px;
  }
  
  .question-section h2 {
    font-size: 28px;
    color: #1a1a1a;
    margin: 0;
    line-height: 1.4;
  }
  
  /* Update options grid */
  .options-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding: 20px;
    margin-top: 20px;
  }
  
  .option-button {
    height: 140px;
    border: 2px solid #eaeaea;
    background: #fff;
    border-radius: 12px;
    transition: all 0.2s ease;
    cursor: pointer;
    padding: 24px;
  }
  
  .option-button:hover {
    border-color: #2D46B9;
    transform: translateY(-2px);
  }
  
  .option-button.selected {
    background: #2D46B9;
    border-color: #2D46B9;
    color: white;
  }

  .countdown-timer {
    text-align: center;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .time-display {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin: 10px 0;
  }

  .register-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 400px;
    margin: 24px auto;
    padding: 24px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px;
  }
  
  .register-form label {
    font-size: 14px;
    color: #37352f;
    font-weight: 500;
  }
  
  .wallet-input {
    padding: 8px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
    transition: border 0.2s ease;
  }
  
  .wallet-input:focus {
    outline: none;
    border-color: #0066ff;
  }
  
  .register-button {
    padding: 6px 12px;
    background: #0066ff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.2s ease;
  }
  
  .register-button:hover {
    background: #0052cc;
  }
  
  .registration-message {
    text-align: center;
    padding: 12px;
    margin: 12px 0;
    color: #37352f;
    font-size: 14px;
  }
  
  .countdown-timer {
    text-align: center;
    padding: 32px;
    margin: 24px auto;
    max-width: 400px;
    background: #f7f7f7;
    border-radius: 8px;
  }
  
  .countdown-timer h2 {
    font-size: 16px;
    color: #37352f;
    font-weight: 500;
    margin-bottom: 12px;
  }
  
  .time-display {
    font-size: 48px;
    font-weight: 600;
    color: #37352f;
  }

  .option-button.correct {
    background-color: #4CAF50;
    color: white;
  }

  .option-button.disabled {
    pointer-events: none;
    opacity: 0.8;
  }

  .completion-frog {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-bottom: 20px;
  }

  .completion-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .waiting-message {
    text-align: center;
    padding: 20px;
    font-size: 1.2em;
    color: #666;
  }

  @media (max-width: 768px) {
    .game-container {
      gap: 1px;
    }
  }

  .buy-btn {
    @apply p-2 flex items-center h-12 w-44 gap-2 pl-6;
    color: #000;
    background-image: url(../../assets/images/button-bg/green.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    font-family: 'Chakra Petch', sans-serif;
    font-weight: 600; 
}