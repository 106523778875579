.hero-container {
    height: 100vh;
    background: url('./BG-05.jpg') no-repeat center center;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-content {
    max-width: 1196px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    height: 100%;
}

.hero-text {
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;
    height: 100%;
    justify-content: end;
}

.hero-heading {
    color: var(--defaultYellow);
    font-size: var(--main-heading);
    margin: 0;
    font-family: 'Eight Bit', sans-serif;
    line-height: 60px;
}

.hero-subheading {
    color: var(--defaultYellow);
    font-size: 36px;
    margin: 0;
    font-family: 'Eight Bit', sans-serif;
    line-height: 36px;
}


.hero-visual {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 448px;
    transform: translateY(80px);
}

.hero-visual img,
.hero-visual video {
    width: 100%;
    height: auto;
}

p {
    color: white;
    font-size: 1.1rem;
    max-width: 500px;
}

.cta-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    max-width: 560px;
}

.cta-buttons button {
    @apply font-bold;
    color: var(--defaultBlack);
    border: none;
    cursor: pointer;
    font-size: 1rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 192px;
}

.primary-btn {
    @apply text-sm md:text-base p-2 flex items-center;
    color: #000;
    background-image: url(../../../../assets/images/button-bg/yellow.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
}

.secondary-btn {
    @apply text-sm md:text-base p-2 flex items-center;
    color: #000;
    background-image: url(../../../../assets/images/button-bg/orange.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
}

.tertiary-btn {
    @apply text-sm md:text-base p-2 flex items-center;
    color: #000;
    background-image: url(../../../../assets/images/button-bg/darkred.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
}

.fourth-btn {
    @apply text-sm md:text-base p-2 flex items-center;
    color: #000;
    background-image: url(../../../../assets/images/button-bg/pink.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
}

.fifth-btn {
    @apply text-sm md:text-base p-2 flex items-center;
    color: #000;
    background-image: url(../../../../assets/images/button-bg/green.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
}

.mobile-img {
    display: none;
}


/* Mobile styles */
@media (max-width: 768px) {
    .hero-container {
        height: auto;
    }

    .hero-content {
        max-width: 1196px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        gap: 0;
    }

    .hero-text {
        align-items: center;
        padding-top: 12rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 0rem;
    }

    .cta-buttons {
        display: none;
    }

    .hero-heading {
        font-size: 80px;
        line-height: 52px;
    }

    .hero-subheading {
        font-size: 28px;
        line-height: 16px;
    }

    .hero-visual {
        display: flex;
        justify-content: center;
        align-items: end;
        transform: translateY(52px);
        margin-top: 2rem;
        position: relative;
        width: 100%;
        background-image: url(../../../../assets/videos/Mobile-Loop.gif);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 720px;
    }

    .hero-visual img,
    .hero-visual video {
        width: 94%;

    }

    .desktop-img {
        display: none;
    }

    .mobile-img {
        display: block;
    }
}

/* Hover effects */
.cta-buttons button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
    transition: all 0.2s ease;
}

/* Make the last button span the width if it's alone in its row */
.cta-buttons button:last-child:nth-child(odd) {
    grid-column: 1 / 2;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .cta-buttons button {
        font-size: 1.25rem;
    }

    .hero-text {
        padding-left: 1rem;
    }

    .hero-heading {
        font-size: 4rem;
        line-height: 3rem;
    }

    .hero-subheading {
        font-size: 2rem;
        line-height: 1rem;
    }

    .hero-visual {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 336px;
        transform: translateY(120px);
    }

    .hero-visual img,
    .hero-visual video {
        width: 100%;
        height: auto;
    }
}