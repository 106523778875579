/* src/components/QuizPanel/QuizzesTable.css */
.quizzes-table-container {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .quizzes-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .quizzes-table th,
  .quizzes-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .quizzes-table th {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  .quizzes-table tr:hover {
    background-color: #e0e0e0;
  }
  
  button {
    margin-right: 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }