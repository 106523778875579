.countdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.timer {
  padding-top: 12px;
  padding-left: 1rem;
  display: flex;
  gap: 8px;
}

.time-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 60px;
}

.number {
  font-size: 32px;
  font-weight: bold;
  color: #333;
}

.label {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}
@media screen and (max-width: 505px) {
  .countdown-container {
    margin-top: 15em;
    /* background-color: red; */
  }
}