/* margin-top: 2em;
padding: 15px;
min-height: 120px;
height: auto;
max-height: 250px;
width: 50%; */

.quiz-card {
  background-size: cover;
  background-color: #FFB6E1;
  background-position: center;
  color: black;
  height: 100%;
  max-height: 260px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.2s ease;
  font-family: 'Chakra Petch', sans-serif;
  padding: 0.75rem;
  width: 222px !important;
  gap: 22px;
  clip-path: polygon(
    0 8px,
    8px 8px,
    8px 0,
    calc(100% - 8px) 0,
    calc(100% - 8px) 8px,
    100% 8px,
    100% calc(100% - 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(100% - 8px) 100%,
    8px 100%,
    8px calc(100% - 8px),
    0 calc(100% - 8px)
  );
}



.quiz-card:hover {
  transform: translateY(-2px);
}

.quiz-header {
  gap: 6px;
}

.up-next {
  color: black;
  width: 100%;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  clip-path: polygon(
    0 3px,
    3px 3px,
    3px 0,
    calc(100% - 3px) 0,
    calc(100% - 3px) 3px,
    100% 3px,
    100% calc(100% - 3px),
    calc(100% - 3px) calc(100% - 3px),
    calc(100% - 3px) 100%,
    3px 100%,
    3px calc(100% - 3px),
    0 calc(100% - 3px)
  );


}

.blitz-number {
  background-color: white;
  width: 100%;
  clip-path: polygon(
    0 3px,
    3px 3px,
    3px 0,
    calc(100% - 3px) 0,
    calc(100% - 3px) 3px,
    100% 3px,
    100% calc(100% - 3px),
    calc(100% - 3px) calc(100% - 3px),
    calc(100% - 3px) 100%,
    3px 100%,
    3px calc(100% - 3px),
    0 calc(100% - 3px)
  );
}

.quiz-details {
  padding-top: 4px;
}

.quiz-datetime {
  color: #94216A;
  font-size: 12px;
  font-weight: 600;
}



.prize-pools {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.prize-tier {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.first-prize {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-image: url('./pink.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.prize-amount {
  font-size: 1.5em;
  color: #94216A;
  font-weight: bold;
}

.prize-label {
  color: #94216A;
  font-size: 0.75rem;
  white-space: nowrap;
}

/* Mobile styles */
@media screen and (max-width: 505px) {
  .quiz-card {
    margin-top: 1rem;
    padding: 15px;
    min-height: 120px;
    height: auto;
    max-height: 250px;
    width: 180px;
  }


  .up-next, .blitz-number {
    font-size: 13px;
  }

  .quiz-details {
    font-size: 15px;
  }



  .quiz-topic {
    font-size: 12px;
  }



  .first-prize {
    width: 50px;
    height: 50px;
  }

  .prize-amount {
    font-size: 1.375em;
  }

  .prize-label {
    font-size: 0.7em;
  }
}