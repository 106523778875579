.header-container {
  max-width: 100%;
  position: absolute;
  padding-top: 1rem;
  padding-bottom: 1rem;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.header-content {
  width: 100%;
  max-width: 1196px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.logo img {
  height: 40px;
  width: auto;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav-links a {
  color: #FFE600;
  text-decoration: none;
  font-family: 'Eight Bit', sans-serif;
  text-transform: uppercase;
  font-size: 24px;
}

.nav-links .trivia-btn {
  background: transparent;
  color: #000;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 48px;
  display: flex;
  align-items: center;
  background-image: url(../../../../assets/images/button-bg/navbar.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.trivia-btn {
  background: transparent;
  color: #000;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 48px;
  display: flex;
  align-items: center;
  background-image: url(../../../../assets/images/button-bg/navbar.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .header-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .nav-links {
    display: none;
  }

  .header-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.header-container-logo-only {
  background: url('./BG-05.jpg') no-repeat center center fixed;
}
