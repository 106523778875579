.add-quiz-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .quiz-title-input {
    width: 100%;
    font-size: 2rem;
    padding: 0.5rem;
    color: black;
    border: none;
    background: transparent;
    border-bottom: 2px solid #eee;
    margin-bottom: 2rem;
    transition: border-color 0.3s ease;
  }
  
  .quiz-title-input:focus {
    outline: none;
    border-color: #0066ff;
  }
  
  .questions-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .question-item {
    background: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .options-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .option-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 4px;
    background: #f5f5f5;
  }
  
  .add-question-btn,
  .submit-quiz-btn {
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
    border: none;
    border-radius: 8px;
    background: #0066ff;
    color: white;
    cursor: pointer;
    font-weight: 500;
  }
  
  .error-display {
    background: #fff1f0;
    border: 1px solid #ffccc7;
    padding: 1rem;
    border-radius: 8px;
    margin: 1rem 0;
    color: #ff4d4f;
  }

  .error-container {
  margin: 1rem 0;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #fff1f0;
  border: 1px solid #ffccc7;
  overflow: hidden;
}

.error-message {
  color: #ff4d4f;
  padding: 0.25rem 0;
  font-size: 0.9rem;
}

.quiz-basic-info {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.quiz-date-picker {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

/* Optional: Style the date picker dropdown */
.react-datepicker-wrapper {
  width: auto;
}




.quiz-basic-info {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .quiz-date-picker {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  /* Optional: Style the date picker dropdown */
  .react-datepicker-wrapper {
    width: auto;
  }

.prize-input-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.prize-currency {
  position: absolute;
  left: 16px;
  color: #666;
  z-index: 1;
}

.quiz-prize-input {
  padding: 8px;
  padding-left: 28px;
  margin: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}