.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* background-color: #1a0f1f; */
  padding: 2rem;
  color: var(--defaultYellow);
  font-family: "Eight Bit", sans-serif;
}

.loading-title {
  font-size: 3rem;
  margin-bottom: 2rem;color: #FFD700;
}

.progress-bar-container {
  width: 100%;
  max-width: 584px;
  margin: 2rem 0;
}

.progress-bar {
  height: 64px;
  background-color: #DD4634;
  display: flex;
  gap: 0.5rem;
  overflow: hidden;
  clip-path: polygon(
    0 4px,
    4px 4px,
    4px 0,
    calc(100% - 4px) 0,
    calc(100% - 4px) 4px,
    100% 4px,
    100% calc(100% - 4px),
    calc(100% - 4px) calc(100% - 4px),
    calc(100% - 4px) 100%,
    4px 100%,
    4px calc(100% - 4px),
    0 calc(100% - 4px)
  );
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
}

.loading-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 584px;
}

.next-round {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.5rem;
}

.coin-icon {
  font-size: 1.8rem;
}

.bonus-badge {
  background-color: var(--defaultYellow);
  color: #1a0f1f;
  padding: 0.5rem 1rem;
  font-weight: bold;
  font-size: 1rem;
  position: relative;
  clip-path: polygon(
    0 4px,
    4px 4px,
    4px 0,
    calc(100% - 4px) 0,
    calc(100% - 4px) 4px,
    100% 4px,
    100% calc(100% - 4px),
    calc(100% - 4px) calc(100% - 4px),
    calc(100% - 4px) 100%,
    4px 100%,
    4px calc(100% - 4px),
    0 calc(100% - 4px)
  );
}

.time-text {
  @apply text-xs md:text-base;
}

.bonus-badge::after {
  position: absolute;
  top: -5px;
  right: -5px;
}
