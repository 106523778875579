/* OptionHandle.css */

.option-handle {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .option-handle.clicked .option-image {
    transform: translateY(12px);
  }
  
  .option-image {
    width: 48px;
    height: 48px;
    transition: transform 0.1s ease;
    transform: translateY(6px);
  }
  
.option-kungj {
    width: 40px;
    height: 40px;
}

  .option-stand {
    width: 8px;
    height: 20px;
    background-color: #000;
    margin-top: -4px;
  }