.trivia-content {
    width: 956px;  /* Desktop width */
    height: 364px;  /* Desktop height */
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 28px;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 585px) {
    .trivia-content {
        width: 260px;  /* Mobile width */
        height: 248px;  /* Mobile height */
        top: 32%;    /* Adjusted mobile positioning */
        /* background-color: red; */
    }
}
.trivia-content {
    user-select: none; /* Prevents text selection */
  }
  
  input.wallet-input, textarea.wallet-input {
    user-select: auto; /* Allows text selection in allowed inputs */
  }