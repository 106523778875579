.bonuses-container {
  padding: 4rem 2rem;
  background-color: var(--darkPurple);
  color: white;
}

.bonuses-content {
  max-width: 1196px;
  margin: 0 auto;
  width: 100%;
}

.bonuses-content h1 {
  font-size: var(--section-heading);
  color: #ffd700;
  margin: 0;
  font-family: 'Eight Bit', sans-serif;
}

.subtitle {
  font-size: 1.2rem;
  margin-bottom: 3rem;
  min-width: 100%;
}

.bonus-cards {
  display: flex;
  gap: 2rem;
}

.bonus-card {
  padding: 2rem;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../../../../assets/images/small-card.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.bonus-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 0.75rem;
}

.bonus-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bonus-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: black;
}

.bonus-card p {
  font-size: 1rem;
  color: black;
  text-align: center;
}

.example-section {
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 0.5rem;
}

.bonus-ranges {
  text-align: center;
  margin-top: 1rem;
}

.status-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 1.5rem;
}

.status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.status-item img {
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
}

.multiplier {
  color: #ffd700;
  font-weight: bold;
  transform: translateY(0.625rem);
}

@media (max-width: 768px) {
  .status-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .bonuses-content h1 {
    font-size: 64px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .bonus-cards {
    flex-direction: column;
  }
}