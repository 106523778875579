.players-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.player-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.player-card img {
    width: 176px;
    height: 200px;
    object-fit: contain;
}

.pr-heading {
    color: var(--defaultYellow);
    font-size: 96px;
    line-height: 88px;
    margin: 0;
    font-family: 'Eight Bit', sans-serif;
}

.bonus-range {
    text-align: center;
    margin-top: 1rem;
}

.player-ranking-container{
    background-color: var(--darkPurple);
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.player-ranking-content{
    max-width: 1196px;
    margin: 0 auto;
    flex-direction: column;
}

.player-ranking-content {
    max-width: 1196px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    padding: 0 2rem;
}

.player-ranking-text {
    max-width: 800px;
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
}

@media (max-width: 768px) {
    .pr-heading {
        font-size: 64px;
        line-height: 64px;
    }

    .player-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 136px;
    }
    
    .player-card img {
        width: 176px;
        height: 200px;
        object-fit: contain;
    }

    .players-grid > *:nth-last-child(1):nth-child(odd) {
        margin: 0 auto; /* Center the last item if it's alone */
    }
}