.question-item {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;
  }
  
  .question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    user-select: none;
  }
  
  .question-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .question-number {
    font-weight: 600;
    color: #666;
    min-width: 2rem;
  }
  
  .question-preview {
    color: #666;
    font-size: 0.9rem;
  }
  
  .question-actions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .question-content {
    padding: 1rem;
    border-top: 1px solid #e0e0e0;
  }
  
  .question-text {
    width: 100%;
    padding: 0.75rem;
    resize: vertical;
    /* min-height: 60px; */
    /* margin-bottom: 2rem; */
  }
  
  .options-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .option-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    background: #f8f8f8;
    border-radius: 4px;
  }
  
  .option-input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  
  .correct-option-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.25rem;
    display: flex;
    align-items: center;
  }
  
  .correct-option-btn .correct {
    color: #00c853;
  }
  
  .delete-btn,
  .delete-option-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    padding: 0.25rem;
  }
  
  .delete-btn:hover,
  .delete-option-btn:hover {
    color: #ff4444;
  }
  
  .add-option-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem;
    background: #f0f0f0;
    border: 1px dashed #ccc;
    border-radius: 4px;
    cursor: pointer;
    color: #666;
    width: 100%;
    margin-top: 0.5rem;
  }
  
  .add-option-btn:hover {
    background: #e8e8e8;
  }

  .correct-option-btn.correct {
    background-color: #4CAF50;
    color: white;
  }

@media screen and (max-width: 585px) {
.question-text {
  margin-bottom: 0.5rem;
}
}