.option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem;
  transition: all 0.3s ease;
  min-width: 100%;
}

.option.selected {
  /* Add your selected state styling */
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.option.correct {
  background-color: #4CAF50; /* or your preferred green color */
  border-color: #45a049;
}

.option.incorrect {
  background-color: #f44336; /* or your preferred red color */
  border-color: #da190b;
}

.option.disabled {
  cursor: default;
  opacity: 0.7;
}

.option-image {
  width: 32px;
  height: 32px;
}

.option-text {
  color: #b9f29f; /* Matches the green color in your screenshot */
}


/* Add media query for mobile devices */
@media screen and (max-width: 768px) {
  .option {
    gap: 0;
  }

  .option-image {
    max-width: 34px;
    max-height: 34px;
    margin: 0 !important;
  }

  .option-text {
    font-size: 0.75rem;
    width: 100%;
    margin-left: -1px;
    font-family: "Chakra Petch", sans-serif;
  }
}