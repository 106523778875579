.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.question-counter {
  font-size: 2rem;
  color: var(--defaultYellow);
}

.progress-indicators {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  justify-content: center;
  /* padding: 10px 10px; */
}

.progress-indicator {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 785px) {
  .progress-container {
    /* position: fixed; */
    /* top: 0;
    left: 0;
    right: 0; */
    gap: 2px;
    /* z-index: 10; */
    /* min-height: 90px; */

    max-height:200px !important;
    /* background-color: black; */

    /* background-color: red; */
  }

  .question-counter {
    font-size: 1.25rem;
    /* margin: 0  7px ; */
    /* margin-bottom: 10px; */

  }

  .progress-indicators {
    gap: 2px;
    padding: 0 5px;
  }

  .progress-indicator {
    width: 12px;
    height: 10px;
  }
}
