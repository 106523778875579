.roadmap-wrapper {
  display: flex;
  flex-direction: column;
  color: white;
  background-color: var(--darkPurple);
  align-items: center;
}

.roadmap-banner {
  height: 21rem;
  display: flex;
  background: url('./../Hero/BG-05.jpg');
  /* background-color: red; */
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: 'Eight Bit', sans-serif;
}

.roadmap-banner h2 {
  text-align: center;
  color: #7b4cd9;
  font-size: 96px;
  line-height: 96px;
}

.roadmap-content {
  /* max-width: 1280px; */
  /* margin: 0 auto; */
  width: 100%;
}

.roadmap-container {
  align-self: center;
  max-width: 1196px;
  height: 148vh;
  padding: 2rem;
  padding-top: 6rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  background-image: url(../../../../assets/images/road-map-bg.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.roadmap-container h2 {
  color: #FFD700;
  font-family: 'Eight Bit', sans-serif;
  font-size: var(--section-heading);
}

.timeline-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
}

.timeline-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.timeline-top-row {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.timeline-number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.active {
  color: var(--defaultYellow);
}

.timeline-phase {
  font-size: 24px;
  font-family: 'Eight Bit', sans-serif;
  margin: 0;
}

.timeline-phase-nr {
  font-size: 96px;
  font-weight: 600;
  font-family: 'Eight Bit', sans-serif;
  margin: 0;
}

.timeline-content-row {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 3rem;
  font-weight: 600;
}

.timeline-bottom-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
}

.padding-left {
  padding-left: 3rem;
}

.timeline-content-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
}

.phase-column {
  flex: 1;
  padding: 0 1rem;
  position: relative;
  max-width: 300px;
}

.phase-header {
  margin-bottom: 2rem;
  position: relative;
}

.phase-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #3a1c78;
  margin-bottom: 1rem;
}

.phase-icon.done {
  background: #FFD700;
}

.phase-status {
  font-size: 0.8rem;
  color: #888;
}

.phase-items {
  list-style: none;
  padding: 0;
}

.phase-items li {
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  font-size: 0.9rem;
}

.bullet {
  color: #FFD700;
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

.completed .bullet {
  color: #FFD700;
}

.completed {
  color: #FFD700;
}

h3 {
  color: #FFD700;
  margin: 0.5rem 0;
}

.timeline-mobile {
  display: none;
}

@media (max-width: 768px) {
  .roadmap-banner {
    height: 30rem;
    display: flex;
    background: url('./../Hero/BG-05.jpg');
    /* background-color: red; */
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    font-family: 'Eight Bit', sans-serif;
  }

  .roadmap-banner h2 {
    text-align: center;
    color: #7b4cd9;
    font-size: 64px;
    line-height: 64px;
  }

  .roadmap-container h2 {
    font-size: 64px;
  }

  .timeline-desktop {
    display: none;
  }

  .roadmap-container {
    background-image: url(../../../../assets/images/roadmap-bg-mobile.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 2666px;
  }

  .timeline-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 2rem;
  }

  .timeline-number-container {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    width: 100%;
  }

  .timeline-phase {
    margin: 0;
    line-height: 24px;
  }

  .timeline-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .roadmap-banner h2 {
    text-align: center;
    color: #7b4cd9;
    font-size: 64px;
    line-height: 58px;
  }
}