.completion-screen {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 956px;  /* Desktop width */
    height: 100%
  }
  
  .completion-screen.winner {
    background-color: #c2ff00;
  }
  
  .completion-screen.loser {
    background-color: #ff4040;
  }
  
  .result-icon {
    flex: 0 0 auto;
    margin-right: 4rem;
  }
  
  .result-icon img {
    width: 200px;
    height: 200px;
    image-rendering: pixelated;
  }
  
  .content-section {
    flex: 1;
    text-align: left;
  }
  
  .completion-message {
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1;
    margin-top: 1rem;
  }
  
  .winner .completion-message {
    color: var(--defaultBlack);
  }
  
  .loser .completion-message {
    color: var(--defaultBlack);
  }
  
  .placeholder-text {
    max-width: 600px;
    margin: 1rem 0;
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  .username {
    background-color: #ff6b6b;
    color: var(--defaultYellow);
    padding: 0.5rem 2rem;
    font-size: 1.5rem;
    /* margin: 1rem 0; */
    margin-bottom: 1rem;
    display: inline-block;
    clip-path: polygon(
      0 4px,
      4px 4px,
      4px 0,
      calc(100% - 4px) 0,
      calc(100% - 4px) 4px,
      100% 4px,
      100% calc(100% - 4px),
      calc(100% - 4px) calc(100% - 4px),
      calc(100% - 4px) 100%,
      4px 100%,
      4px calc(100% - 4px),
      0 calc(100% - 4px)
    );
  }
  
  .winner .username {
    background-color: #ff6b6b;
  }
  
  .prize-calculation {
    /* background-color: rgba(255, 255, 255, 0.2); */
    border-radius: 8px;
    /* margin-top: 2rem; */
    width: fit-content;
    color: var(--defaultBlack);
    font-size: 1rem;
    font-family: 'Chakra Petch', sans-serif;
  }
  
  .calculation-row {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 0.5rem 0; 
  }
  
  .label {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.1rem;
  }

  .polygony {
    clip-path: polygon(
    0 2px,
    2px 2px,
    2px 0,
    calc(100% - 2px) 0,
    calc(100% - 2px) 2px,
    100% 2px,
    100% calc(100% - 2px),
    calc(100% - 2px) calc(100% - 2px),
    calc(100% - 2px) 100%,
    2px 100%,
    2px calc(100% - 2px),
    0 calc(100% - 2px)
  );
  }
  
  .multiplier-info {
    font-size: 1rem;
    color: #000;
    font-family: "Chakra Petch", sans-serif;
  }
  
  @media screen and (max-width: 586px) {
    .completion-screen {
      margin: 0 auto;
      flex-direction: column;
      text-align: center;
      /* background-color: red; */
      width: 312px;
      height: 304px;
      transform: translateX(-27px) translateY(-26px);
     /* height: 100%; */
    }

    .result-icon {
      /* background-color: red; */
      margin-right: 0;
      padding-top: 1rem;
    }

    .result-icon img {
      width: 40px;
      height: auto;
      /* margin-bottom: 1rem; */
    }

    .content-section {
      text-align: center;
      /* background-color: red; */
    }

    .completion-message {
      font-size: 2rem;
      /* margin: 0.5rem 0; */
      text-align: center;
    }

    .username {
      font-size: 1rem;
      max-width: 300px;
      /* margin: 0.5rem 0; */
      text-wrap: wrap;
    }

    .multiplier-info {
      display: none;
    }

    .prize-calculation {
      display: flex;
      justify-content: center;
      width: 100%;
      font-size: 1rem;
      /* margin: 1rem auto; */
    }

    .calculation-row {
      gap: 0.5rem;
    }

    .calculation-row .label {
      font-size: 0.75rem;
    }
  }