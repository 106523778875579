.free-to-play-container {
  position: relative;
  background-color: var(--darkPurple);
  height: 110vh;
  background-image: url(../../../../assets/images/pixel-bg-desktop.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 4;
  max-width: 100vw;
}

.content-wrapper {
  max-width: 1196px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.ftp-top-container {
  display: flex;
  gap: 2rem;
}

.ftp-text-container {
  padding-top: 4rem;
}

.text-content {
  flex: 1;
}

.ftp-heading {
  color: var(--defaultRed);
  font-size: var(--section-heading);
  margin: 0;
  font-family: 'Eight Bit', sans-serif;
}

h3 {
  color: var(--defaultRed);
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  margin-top: -2.5rem;
}

.description {
  color: var(--defaultBlack);
  margin-bottom: 2rem;
  max-width: 600px;
  margin-top: 0.75rem;
  font-weight: 600;
}

.features-columns {
  display: flex;
  gap: 2rem;
}

.left-column,
.right-column {
  flex: 1;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  color: var(--defaultBlack);
  font-weight: 600;
  font-size: 1rem;
  align-items: center;
}

.feature-item img {
  width: 32px;
}

.arrow {
  color: #333;
  font-weight: bold;
}

.feature-item.highlight {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.feature-item.dark {
  background-color: #1a1a1a;
  color: white;
  padding: 1rem;
  padding-left: 0.5rem;
}

.nintendo-image {
  display: flex;
  align-items: center;
  max-width: 564px;
  width: 100%;
  transform: rotate(12deg) translateY(3rem);
  z-index: 99;
}

.nintendo-image img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 1440px) {
  .free-to-play-container {
    position: relative;
    background-color: var(--darkPurple);
    height: 100vh;
    background-image: url(../../../../assets/images/pixel-bg-desktop.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 4;
    max-width: 100vw;
    padding-top: 3rem;
  }

  .nintendo-image {
    transform: rotate(13deg) translateX(6rem) translateY(-5rem);
  }
}

@media (max-width: 768px) {
  .free-to-play-container {
    height: auto;
    background-image: url(../../../../assets/images/f2p-bg-mobile.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 1096px;
  }

  .content-wrapper {
    flex-direction: column;
  }

  .features-columns {
    flex-direction: column;
  }

  .ftp-top-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 0rem;
  }

  .ftp-text-container {
    padding-top: 2rem;
    gap: 0;
  }

  .nintendo-image {
    display: flex;
    align-items: center;
    max-width: 320px;
    width: 100%;
    transform: rotate(13deg) translateX(6rem) translateY(-3rem);
  }

  .nintendo-image img {
    max-width: 100%;
  }

  .ftp-heading {
    font-size: 64px;
    line-height: 64px;
    margin: 0;
  }

  .content-wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  h3 {
    color: var(--defaultRed);
    margin: 0;
    font-size: 24px;
  }

  .features-columns {
    display: flex;
    gap: 0rem;
  }

  .content-wrapper {
    gap: 0rem;
  }

  .description {
    color: var(--defaultBlack);
    margin-bottom: 1rem;
    max-width: 600px;
    margin-top: 0.5rem;
    font-weight: 600;
  }

  .feature-item {
    font-size: 14px;
  }

  .feature-item.dark {
    background-color: #1a1a1a;
    color: white;
    padding: 0.75rem;
    padding-left: 0.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .free-to-play-container {
    height: 160vh;
    background-size: cover;
  }


  .ftp-heading {
    font-size: 58px;
    line-height: 52px;
    margin: 0;
  }

  .ftp-text-container {
    padding-top: 8rem;
    padding-left: 1rem;
  }

  h3 {
    color: var(--defaultRed);
    margin: 0;
    font-weight: 500;
    font-size: 24px;
  }

}