powerups-content.powerups-container {
  background-color: var(--darkPurple);
  position: relative;
}

.powerups-content {
  max-width: 1196px;
  margin: 0 auto;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.powerups-content h1 {
  font-size: var(--section-heading);
  font-family: 'Eight Bit', sans-serif;
  color: var(--defaultYellow);
  line-height: 1.2;
  text-align: left;
  margin-bottom: 1rem;
  margin: 0;
}

.powerups-content > p {
  color: white;
  font-size: 1.1rem;
  max-width: 800px;
  margin-bottom: 3rem;
}

.powerups-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  max-width: 1196px;
}

.powerup-item {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.powerup-icon {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
}

.powerup-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.powerup-text h3 {
  color: #fff;
  font-weight: 600;
  margin: 0;
  font-size: 1.25rem;
}

.powerup-text p {
  color: white;
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .powerups-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .powerups-content {
    padding-left: 1rem;
  }

  .powerups-content h1 {
    font-size: 64px;
  }
} 