.game-types-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--darkPurple);
  gap: 2rem;
  padding-bottom: 12rem;
  padding-top: 4rem;
}

.game-types-content {
  max-width: 1196px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
}

.game-types-text {
  width: 100%;
}

.game-types-text h2 {
  font-size: var(--section-heading);
  font-family: 'Eight Bit', sans-serif;
  text-align: left;
  margin: 0;
  margin-bottom: 1rem;
}

.game-types-grid {
  display: flex;
  flex-direction: row;
  /* background-color: #FF4B4B; */
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
  /* flex-wrap: wrap; */
  max-width: 1196px;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.game-type {
  flex: 1;
  min-width: 300px;
  padding: 2rem;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  background-image: url(../../../../assets/images/big-bg.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  align-items: center;
  width: 100%;
  min-width: 600px;
  max-width: 600px;
  transition: all 0.3s ease;
}

.gametype-btn {
  background-image: url(../../../../assets/images/gametype-button.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 200px;
  /* background-color: #FF4B4B; */
  height: 88px;
  transform: translateY(-52px);
  font-family: "Eight Bit", sans-serif;
}

.game-type-header {
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 1rem; */
  margin: 0;
  margin-bottom: 1rem;
}

.game-type-header h3 {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
}

.type-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: 'Chakra Petch', sans-serif;
  font-weight: 600;
}

.feature-list li {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  color: #333;
  font-size: 0.9rem;
}

.arrow {
  color: #FF4B4B;
  font-weight: bold;
  font-size: 1.2rem;
}

.diagram {
  margin-top: 2rem;
  text-align: center;
}

.diagram-img {
  max-width: 50%;
  height: auto;
}

@media (max-width: 768px) {
  .game-types-grid {
    flex-direction: column;
    padding: 0 1rem;
    background-size: 100% 100%; 
    align-items: center;
  }

  .game-type {
    /* width: 340px; */
    min-width: 340px;
    max-width: 340px;
    background-image: url(../../../../assets/images/gametypeBgMobile.png);
    background-size: 100% 100%;
  }

  .gametype-btn {
    width: 144px;
    height: 88px;
    transform: translateY(-52px);
  }

  .game-type-content {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 2rem;
  }

  .diagram {
    margin-top: auto;
    padding-bottom: 1rem;
  }

  .pvp {
    display: none;
    height: 0;
  }

  .game-types-text h2 {
    font-size: 64px;
    text-align: left;
    margin: 0;
    margin-bottom: 1rem;
  }

  .activeMode {
    background-image: url(../../../../assets/images/cardBtnMobYellow.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 144px;
    height: 88px;
    transform: translateY(-52px);
  }

  .game-types-grid {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .game-types-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
    flex-wrap: wrap;
    max-width: 1196px;
    margin: 0 auto;
    padding: 0;
  }
}