@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Chakra Petch';
  src: url('./assets/fonts/chakra-petch/ChakraPetch-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Chakra Petch';
  src: url('./assets/fonts/chakra-petch/ChakraPetch-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Chakra Petch';
  src: url('./assets/fonts/chakra-petch/ChakraPetch-Light.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Chakra Petch';
  src: url('./assets/fonts/chakra-petch/ChakraPetch-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Chakra Petch';
  src: url('./assets/fonts/chakra-petch/ChakraPetch-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Eight Bit';
  src: url('./assets/fonts/eight-bit/Eight-Bit-Madness.ttf') format('truetype');
  font-weight: 200;
}

html {
  scroll-behavior: smooth;
}

:root {
  --defaultRed: #FF533F;
  --defaultBlack: #1C041E;
  --main-heading: 98px;
  --section-heading: 88px;
  --darkPurple: #1C041E;
  --contentWidth: 1196px;
  --defaultYellow: #FFD601;
  --defaultOrange: #FFB940;

}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Chakra Petch', sans-serif;
  color: var(--defaultBlack);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .desktop-image {
    display: none;
  }

  .topiii {
    top: 143% !important;
  }

  .topi2 {
    padding-bottom: 50%;
  }

  .mobile-image {
    display: block !important;
  }
}

.mobile-image {
  display: none;
}
.no-scroll {
  overflow: hidden;
  height: 100vh;
}

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.pixel-font {
  font-family: 'Press Start 2P', cursive;
}