.registration-container-countdown {
  color: var(--defaultYellow);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.countdown-section {
  text-align: center;
}

.countdown-label {
  font-size: 2rem;
  color: #ffd700;
  margin-bottom: 1rem;
}

.countdown-display {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.digit {
  background-color: var(--defaultYellow);
  padding: 0.5rem;
  font-size: 3rem;
  color: black;
  min-width: 3rem;
  text-align: center;
  clip-path: polygon(
    0 4px,
    4px 4px,
    4px 0,
    calc(100% - 4px) 0,
    calc(100% - 4px) 4px,
    100% 4px,
    100% calc(100% - 4px),
    calc(100% - 4px) calc(100% - 4px),
    calc(100% - 4px) 100%,
    4px 100%,
    4px calc(100% - 4px),
    0 calc(100% - 4px)
  );
}

.digit-separator {
  font-size: 3rem;
  color: var(--defaultYellow);
  padding: 0.5rem 0;
}

.address-section {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.address-label {
  font-size: 2.5rem;
  color: var(--defaultYellow);
}

.input-group {
  display: flex;
  gap: 10px;
}

.wallet-input {
  background-color: var(--defaultYellow);
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1.2rem;
  font-family: 'Chakra Petch', sans-serif;
  width: 400px;
  color: #666;
  clip-path: polygon(
    0 4px,
    4px 4px,
    4px 0,
    calc(100% - 4px) 0,
    calc(100% - 4px) 4px,
    100% 4px,
    100% calc(100% - 4px),
    calc(100% - 4px) calc(100% - 4px),
    calc(100% - 4px) 100%,
    4px 100%,
    4px calc(100% - 4px),
    0 calc(100% - 4px)
  );
}

.confirm-button {
  background-color: #ff6b6b;
  color: black;
  border: none;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  font-size: 1.2rem;
}

.warning-text {
  color: var(--defaultYellow);
  text-decoration: underline;
  font-size: 1.2rem;
}

.confirmed-status {
  color: black;
  padding: 0.8rem 1.5rem;
  font-size: 1.75rem;
  position: relative;
  display: flex;
  align-items: center;
  transform: translateX(-1.5rem) scale(1.05);
  justify-content: center;
  width: 8rem;
  gap: 0.5rem;
  clip-path: polygon(
    0 4px,
    4px 4px,
    4px 0,
    calc(100% - 4px) 0,
    calc(100% - 4px) 4px,
    100% 4px,
    100% calc(100% - 4px),
    calc(100% - 4px) calc(100% - 4px),
    calc(100% - 4px) 100%,
    4px 100%,
    4px calc(100% - 4px),
    0 calc(100% - 4px)
  );
}

.checkmark {
  font-size: 1.2rem;
  margin-right: 4px;
}

.wallet-input:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.quiz-started-message {
  color: #ff0000; /* or whatever color you prefer */
  font-size: 1.5em;
  font-weight: bold;
}

.home-link {
  margin-top: 15px;
  font-size: 0.8em;
}

.home-link a {
  color: #ffd700;
  text-decoration: none;
  transition: color 0.2s ease;
}

.home-link a:hover {
  color: #fff;
  text-decoration: underline;
}

@media screen and (max-width: 785px) {
  .registration-container-countdown {
    gap: 1rem;
  }

  .countdown-label {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .digit {
    font-size: 2rem;
    min-width: 2rem;
    padding: 0.3rem;
  }

  .digit-separator {
    font-size: 2rem;
    padding: 0.3rem 0;
  }

  .address-section {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;
  }

  .address-label {
    font-size: 1.8rem;
  }

  .input-group {
    width: 100%;
  }

  .wallet-input {
    width: 100%;
    max-width: 250px;
    padding: 0.6rem 1rem;
    font-size: 1rem;
  }

  .confirm-button {
    padding: 0.6rem 1rem;
    font-size: 1rem;
  }

  .confirmed-status {
    transform: none;
    width: auto;
    padding: 0.6rem 1rem;
    font-size: 1rem;
  }

  .warning-text {
    font-size: 1rem;
  }
}
