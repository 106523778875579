.footer {
  position: relative;
  background-image: url('../../../../assets/images/footerBg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 4rem;
  padding-bottom: 1.25rem;
  height: 65vh;
  background-color: var(--darkPurple);
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.footer-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: var(--contentWidth);
  margin: 0 auto;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0;
}

.tamago-logo {
  height: 48px;
  width: auto;
}

.logo-text {
  font-size: 1.5rem;
  font-weight: bold;
}

.footer-title {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 2rem;
  columns: var(--defaultBlack);
  font-family: 'Eight Bit', sans-serif;
  margin: 0;
  margin-top: 1.5rem;
  margin-bottom: 2rem;

}

.social-links {
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;
}

.social-link {
  width: 40px;
  height: 40px;
  background-color: #1a0f2e;
  color: #ffd700;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: bold;
  transition: transform 0.2s ease;
}

/* .social-link:hover {
  transform: translateY(-2px);
} */

.copyright {
  font-size: 0.875rem;
  color: var(--defaultBlack);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer {
    padding: 3rem 1rem 1.5rem;
  }

  .footer-title {
    font-size: 2rem;
  }

  .social-links {
    flex-wrap: wrap;
    justify-content: center;
  }
}
